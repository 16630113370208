/*

*/
/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. welcome
06. features
07. team
08. pricing
09. blog
10. contact
11. footer
12. preloader

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url('https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,700,900');
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 100px;
  height: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(181, 181, 181, 0.8);
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  background-color: #4b4b4b;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #4b4b4b;
  font-family: 'Raleway', sans-serif;
}

::selection {
  background: #0771e5;
  color: #fff;
}

::-moz-selection {
  background: #0771e5;
  color: #fff;
}

.section {
  background: #4b4b4b;
  position: relative;
  padding-top: 100px;
  padding-bottom: 80px;
}

.section.colored {
  background: #323030;
}

.hr {
  bottom: 0px;
  width: 100%;
  height: 1px;
  margin-top: 100px;
  border-bottom: 1px solid #eee;
}

.left-heading.light .section-title {
  color: #ffffff;
}

.left-heading .section-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #ffffff;
  letter-spacing: 0.25px;
  margin-bottom: 30px;
  position: relative;
}

.center-heading {
  text-align: center;
}

.center-heading .section-title {
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  letter-spacing: 1.75px;
  line-height: 38px;
  margin-bottom: 50px;
}

.center-heading.colored .section-title {
  color: #ffffff;
}

.center-text {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #777;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}

.center-text.colored {
  color: #fff;
}

.center-text p {
  font-size: 15px;
  color: #777;
  margin-bottom: 30px;
}

.left-text {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  letter-spacing: 1px;
  text-align: justify;
}

.left-text.light {
  color: #fff;
}

.left-text p {
  margin-bottom: 30px;
}

.left-text p.dark {
  color: #3b566e;
}

.padding-bottom-top-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.padding-bottom-80 {
  padding-bottom: 80px !important;
}

.padding-bottom-100 {
  padding-bottom: 100px !important;
}

.border-bottom {
  border-bottom: 1px solid #eee !important;
}

.mbottom-30 {
  margin-bottom: 30px !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-bottom {
  -ms-flex-item-align: flex-end !important;
  align-self: flex-end !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-80 {
  padding-top: 80px !important;
}

.padding-top-70 {
  padding-top: 70px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-bottom-45 {
  margin-bottom: 45px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-bottom-100 {
  margin-bottom: 100px !important;
}

@media (max-width: 991px) {
  html,
  body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

a.main-button-slider {
  font-size: 13px;
  border-radius: 20px;
  padding: 12px 20px;
  background-color: #37a66f;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.25px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

a.main-button-slider:hover {
  background-color: #2b9862;
}

a.main-button {
  font-size: 13px;
  border-radius: 20px;
  padding: 12px 20px;
  background-color: #37a66f;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.25px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

a.main-button:hover {
  background-color: #2b9862;
}

a.main-button.prijava-btn {
  margin-bottom: 20px;
}

button.main-button {
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 13px;
  border-radius: 20px;
  padding: 12px 20px;
  background-color: #37a66f;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.25px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.prijava-container {
  position: relative;
  bottom: 10px;
}

/* 
---------------------------------------------
header
--------------------------------------------- 
*/
.header-area {
  position: fixed;
  top: 30px;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 100px;
  -webkit-transition: all 0.26s ease-in-out;
  -moz-transition: all 0.26s ease-in-out;
  -o-transition: all 0.26s ease-in-out;
  transition: all 0.26s ease-in-out;
}

.header-area-top {
  top: 0px;
}

.header-area .main-nav {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  min-height: 80px;
  background: #323030;
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
}

.header-area .main-nav .logo {
  float: left;
  margin-top: 37px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-left: 30px;
}

.header-area .main-nav .logo.logo-normal {
  margin-left: 20px !important;
}

.logo-hidden {
  display: none !important;
}

.centered-nav {
  margin: 0 auto;
  width: fit-content;
  box-shadow: none !important;
  display: table;
}

.show-logo {
  display: flex !important;
}

.header-area .main-nav .logo img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 27px;
  margin-left: 0px;
  margin-right: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.nav-anim {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  padding-right: 20px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li a:hover {
  color: #2b9862;
  border-bottom: 2px solid #2b9862;
}

.header-area .main-nav .menu-trigger {
  color: #ffffff;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 23px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #ffffff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #ffffff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: '';
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #ffffff;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #ffffff;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-area.header-sticky .logo {
  margin-top: 20px;
}

.header-area.header-sticky .nav {
  margin-top: 20px !important;
}

.header-area.header-sticky .nav li a {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid#ffffff;
}

.header-area.header-sticky .nav li a.active {
  color: #37a66f;
  border-bottom: 2px solid #37a66f;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 991px) {
  .centered-nav {
    margin: auto;
    width: 100%;
  }

  .header-area .main-nav .logo {
    display: flex;
  }

  .header-area {
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-top: 20px !important;
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    margin-top: 80px !important;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #4b4b4b;
    border-bottom: 1px solid #ffffff;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #4b4b4b !important;
    color: #ffffff !important;
  }
  .header-area .main-nav .nav li:last-child {
    border: none;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
  }
}

@media (min-width: 992px) {
  .logo.logo-mobile {
    display: none !important;
  }

  .header-area .main-nav .nav {
    display: flex !important;
  }
}

/* 
---------------------------------------------
welcome
--------------------------------------------- 
*/
.welcome-area {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../images/banner-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
}

.welcome-area .header-text {
  position: absolute;
  top: 41%;
  transform: translateY(-55%);
  text-align: center;
  width: 100%;
}

.header-text img {
  width: 612px;
  height: 139px;
}

.welcome-area .header-text h1 {
  font-weight: 500;
  font-size: 50px;
  line-height: 54px;
  letter-spacing: 1.4px;
  margin-bottom: 30px;
  color: #fff;
  animation-name: resize-text;
  animation-duration: 1s;
}

@keyframes resize-text {
  from {
    font-size: 40px;
    opacity: 0;
  }
  to {
    font-size: 50px;
    opacity: 1;
  }
}

@keyframes resize-paraf {
  from {
    font-size: 10px;
    opacity: 0;
  }
  to {
    font-size: 20px;
    opacity: 1;
  }
}

.welcome-area .header-text p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  line-height: 28px;
  letter-spacing: 0.25px;
  margin-bottom: 40px;
  position: relative;
  animation-name: resize-paraf;
  animation-duration: 1s;
}

@media (max-width: 991px) {
  .header-text img {
    width: 486px;
    height: 110px;
  }

  .prijava-container {
    position: relative;
    bottom: -20px;
  }

  .welcome-area .header-text {
    position: absolute;
    top: 45%;
    transform: translateY(-55%);
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .welcome-area .header-text h1 {
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
  }
  .welcome-area .header-text h1 span {
    color: #fff;
  }
  .welcome-area .header-text p {
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
  }
}

.all-container {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  width: 100vw;
  max-width: 100vw;
}

.col-12-mob {
  padding: 0px;
}

.time-to {
  margin: 0 auto;
}

@media (max-width: 820px) {
  .header-text img {
    width: 360px;
    height: 82px;
  }

  .welcome-area .header-text {
    position: absolute;
    top: 45%;
    transform: translateY(-55%);
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .welcome-area .header-text h1 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .welcome-area .header-text p {
    margin-bottom: 20px;
  }
}

@media (max-width: 765px) {
  .welcome-area {
    margin-bottom: 100px;
  }
  .welcome-area .header-text {
    position: absolute;
    top: 45%;
    transform: translateY(-55%);
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .welcome-area .header-text .buttons {
    display: none;
  }
  .welcome-area .header-text h1 {
    font-weight: 600;
    font-size: 24px !important;
    line-height: 30px !important;
    margin-bottom: 30px !important;
  }
  .welcome-area .header-text h1 span {
    color: #fff;
  }
  .welcome-area .header-text p {
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 40px;
  }

  .time-to {
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
  }

  .time-to .time-col {
    flex: 1;
  }
}

/* 
---------------------------------------------
features
--------------------------------------------- 
*/
.features-small-item {
  cursor: pointer;
  display: block;
  background: #4b4b4b;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  margin-bottom: 30px;
}

.features-small-item:hover .icon {
  opacity: 0.8;
}

.features-small-item .icon {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin: auto;
  position: relative;
  margin-bottom: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.features-small-item .icon i {
  font-size: 18px;
  color: #fff;
}

.features-small-item .icon i img {
  width: 60px;
  height: 60px;
}

.ucesnici-number {
  display: flex;
  justify-content: center;
}

@keyframes plus-animation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.plus {
  font-size: 20px !important;
  animation-name: plus-animation;
  animation-duration: 3s;
}

.features-small-item .features-title {
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  letter-spacing: 0.7px;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
}

.features-small-item p {
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 25px;
  position: relative;
  z-index: 2;
}

.home-feature {
  padding-bottom: 0px;
  padding-top: 30px;
  margin-top: -310px;
  z-index: 9;
  background: transparent;
}

@media (max-width: 991px) {
  .home-feature {
    padding-bottom: 0px;
    padding-top: 0px;
    margin-top: -250px;
  }
}

/* 
---------------------------------------------
parallax & home seperator
--------------------------------------------- 
*/
.mini {
  background: #4b4b4b;
  min-height: 215px;
  overflow: hidden;
  position: relative;
  padding-top: 90px;
  padding-bottom: 90px;
}

.mini:before {
  content: '';
  position: absolute;
  width: 140%;
  height: 140%;
  opacity: 0.95;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url(../images/work-process-bg.png);
  z-index: 2;
  top: -20%;
  left: -20%;
}

.mini .mini-content {
  position: relative;
  z-index: 3;
}

.mini .mini-content .info {
  text-align: center;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.mini .mini-content .info small {
  display: block;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 5px;
}

.mini .mini-content .info strong {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 40px;
  display: block;
}

.mini .mini-content .info h1 {
  color: #fff;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 0.25px;
  margin-bottom: 30px;
}

.mini .mini-content .info p {
  letter-spacing: 1px;
  margin-bottom: 40px;
  color: #fff;
  letter-spacing: 0.25px;
  line-height: 26px;
  font-weight: 400;
  font-size: 15px;
}

.mini .mini-content .info p span {
  text-decoration: underline;
}

.mini .mini-content .mini-box {
  display: block;
  background: #4b4b4b;
  padding: 20px;
  min-height: 150px;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 #37a66f;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: center;
}

.mini .mini-content .mini-box:hover {
  margin-top: -10px;
}

/* .mini .mini-content .mini-box:before {
  content: '';
  position: absolute;
  z-index: -1;
  background: #fff;
  width: 100%;
  opacity: 0.15;
  height: 100%;
  bottom: -10px;
  left: 0px;
  right: -20px;
  margin: auto;
  border-radius: 20px;
} */

.mini .mini-content .mini-box .sadrzaj-img {
  width: 40px;
  height: 40px;
}

.mini .mini-content .mini-box .sadrzaj-opis {
  color: #ffffff;
  font-weight: 600;
}

.mini .mini-content .mini-box i {
  display: block;
  font-size: 20px;
  margin-bottom: 15px;
}

.mini .mini-content .mini-box span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #777;
  letter-spacing: 0.75px;
}

.mini .mini-content .mini-box strong {
  display: block;
  font-weight: 400;
  font-size: 17px;
  color: #1e1e1e;
  letter-spacing: 0.25px;
  margin-bottom: 5px;
  margin-top: 20px;
}

.counter {
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}

.counter:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 2;
  top: -20%;
  left: -20%;
}

.counter .content {
  position: relative;
  z-index: 3;
  width: 100%;
}

.counter .content .count-item {
  height: 140px;
  position: relative;
  float: left;
  width: 100%;
}

.counter .content .count-item.decoration-bottom {
  position: relative;
}

.podrska-section p {
  color: #37a66f;
  margin: 0 auto;
  font-size: 42px;
  font-weight: bold;
}

.podrska-section p:hover {
  color: #2b9862;
  cursor: pointer;
  font-size: 42px;
  font-weight: bold;
}

.counter .content .count-item.decoration-bottom:after {
  content: '';
  position: absolute;
  width: 70%;
  height: 32px;
  top: 160px;
  left: 70%;
  /* background: url(../images/circle-dec.png) center center no-repeat; brojac kruzici*/
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.counter .content .count-item.decoration-top {
  position: relative;
}

.counter .content .count-item.decoration-top:after {
  content: '';
  position: absolute;
  width: 70%;
  height: 58px;
  top: 140px;
  left: 70%;
  background: url(../images/sat-01.png) center center no-repeat;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.counter .content .count-item:hover strong {
  margin-top: 10px;
}

.counter .content .count-item strong {
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 42px;
  letter-spacing: 0.25px;
  margin-bottom: 10px;
  color: #37a66f;
  margin-top: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.counter .content .count-item span {
  display: block;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.25px;
}

@media (max-width: 991px) {
  .parallax {
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: auto;
  }
  .parallax .content {
    position: relative !important;
    top: 0% !important;
    transform: perspective(1px) translateY(0%) !important;
  }
  .counter {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .counter .content {
    position: relative !important;
    top: 0% !important;
    transform: perspective(1px) translateY(0%) !important;
  }
  .counter .content .count-item {
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .counter .content .count-item:hover strong {
    margin-top: 0px;
  }
  .counter .content .count-item:before {
    display: none;
  }
  .counter .content .count-item:after {
    display: none;
  }
  .counter .content .count-item strong {
    margin-top: 0px;
    font-size: 26px;
  }
  .counter .content .count-item span {
    font-size: 14px;
  }
}

/* 
---------------------------------------------
team
--------------------------------------------- 
*/
.team-item {
  background: #323030;
  box-shadow: 0 2px 12px 0 #37a66f;
  border-radius: 20px;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 25px;
}

.team-item:hover .team-content .team-info {
  margin-left: 30px;
}

.team-item i {
  display: block;
  margin-left: 25px;
  margin-top: 25px;
  font-size: 30px;
  color: #4e47de;
  text-align: center;
}

.team-item .user-image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-left: 25px;
  float: left;
}

.team-item .user-image .img-wrapper {
  width: 60px;
  height: 60px;
}

.team-item .team-content {
  min-height: 410px;
  text-align: left;
  overflow: hidden;
}

.team-item .team-content .position {
  position: absolute;
  bottom: 30px;
}

.team-item .team-content .position .team-info {
  float: left;
  margin-left: 20px;
  margin-top: 8px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.team-item .team-content .position .team-info .user-name {
  display: block;
  font-weight: 400;
  font-size: 17px;
  color: #ffffff;
  letter-spacing: 0.25px;
  margin-bottom: 5px;
}

.team-item .team-content .position .team-info span {
  display: block;
  font-weight: bold;
  font-size: 15px;
  color: #37a66f;
  letter-spacing: 0.25px;
}

.team-item .team-content p {
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0.6px;
  line-height: 26px;
  text-align: justify;
}

/* 
---------------------------------------------
pricing
--------------------------------------------- 
*/
.pricing-item {
  background: #4b4b4b;
  box-shadow: 0 2px 12px 0 #171717;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 30px 0px 40px 0px;
  min-height: 615px;
}

.pricing-item.active .pricing-header {
  position: relative;
}

.pricing-item.active .pricing-header .pricing-title {
  color: #ffffff;
}

.pricing-item.active .pricing-body .price-wrapper {
  width: 100%;
  padding: 30px;
}

.pricing-item .pricing-header {
  text-align: center;
  display: block;
  position: relative;
  padding-bottom: 10px;
}

.pricing-item .pricing-header .pricing-title {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.25px;
  color: #ffffff;
  width: 180px;
  height: 40px;
  line-height: 40px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.pricing-item .pricing-body {
  margin-bottom: 40px;
}

.pricing-item .pricing-body .price-wrapper {
  text-align: center;
  border-radius: 20px;
  width: calc(100% - 20px);
  height: 150px;
  padding: 20px 30px;
  margin-bottom: 20px;
  border: 1px solid gray;

  margin-left: 10px;
  margin-right: 10px;
}

.pricing-item .pricing-body .list li {
  text-align: center;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0.25px;
  text-decoration: line-through;
}

.pricing-item .pricing-body .list li.active {
  color: #ffffff;
  text-decoration: none;
}

.pricing-item .pricing-footer {
  /* text-align: center;
  margin-top: 50px; */
  position: absolute;
  bottom: 10%;
  /* position: fixed; */
  display: block;
  right: calc(50% - 68px);
}

/* 
---------------------------------------------
blog
--------------------------------------------- 
*/
.blog-post-thumb {
  text-align: center;
  margin-bottom: 30px;
  min-height: 500px;
  /* background: red; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  padding-top: 10px;
}

.blog-post-thumb img {
  border-radius: 10px;
}

.blog-post-thumb.big .img {
  height: 400px;
  border-radius: 20px;
}

.blog-post-thumb .img {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  height: 200px;
}

.blog-post-thumb .blog-content {
  margin-top: -30px;
  padding-top: 50px;
}

.blog-post-thumb h3 {
  margin-bottom: 20px !important;
}

.blog-post-thumb h3 a {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0.25px;
  line-height: 26px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.blog-post-thumb h3 a:hover {
  color: #2b9862;
}

.blog-post-thumb .text {
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0.26px;
  line-height: 26px;
  margin-bottom: 15px;
  margin: 20px;
  text-align: justify;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-post-thumb .post-footer span {
  float: left;
  height: 30px;
  line-height: 30px;
  font-weight: 300;
  font-size: 14px;
  color: #ffffff;
  padding-left: 30px;
}

.blog-post-thumb a.main-button {
  margin-top: 10px;
  display: inline-block;

  position: absolute;
  bottom: 10%;
  /* position: fixed; */
  display: block;
  right: calc(50% - 68px);
}

.blog-list .blog-post-thumb {
  text-align: left;
}

/* 
---------------------------------------------
contact
--------------------------------------------- 
*/

#contact-us {
  padding: 160px 0px;
}

#contact-us h5 {
  font-weight: 500;
  font-size: 18px;
  color: #1e1e1e;
  letter-spacing: 0.25px;
  line-height: 26px;
}

.contact-text {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.6px;
  line-height: 26px;
}

.contact-text p {
  margin-bottom: 28px;
}

.contact-form input,
.contact-form textarea {
  color: #777;
  font-size: 14px;
  border: 1px solid #eee;
  width: 100%;
  height: 50px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 30px;
}

.contact-form textarea {
  height: 150px;
  resize: none;
  padding: 20px;
}

/* 
---------------------------------------------
footer
--------------------------------------------- 
*/
footer {
  background: rgb(75, 75, 75);
  background: linear-gradient(
    90deg,
    rgba(75, 75, 75, 1) 100%,
    rgba(23, 106, 65, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  padding-top: 30px;
}

footer .social {
  overflow: hidden;
  margin-top: 10px;
  text-align: center;
}

footer .social li {
  margin: 0px 10px;
  display: inline-block;
}

footer .social li a {
  color: #37a66f;
  text-align: center;
  background-color: #fff;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  display: inline-block;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

footer .social li a:hover {
  background-color: #2b9862;
  color: #fff;
}

footer .copyright {
  text-align: center;
  border-top: 1px solid #ffffff;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.88px;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  footer .text {
    margin-bottom: 30px;
  }
  footer h5 {
    margin-bottom: 15px;
  }
  footer .footer-nav {
    margin-bottom: 30px;
  }
}

/* 
---------------------------------------------
preloader
--------------------------------------------- 
*/
#preloader {
  overflow: hidden;
  background-image: linear-gradient(135deg, #37a66f 0%, #0771e5 100%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  color: #fff;
}

#preloader .jumper {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
}

#preloader .jumper > div {
  background-color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
  -webkit-animation: jumper 1s 0s linear infinite;
  animation: jumper 1s 0s linear infinite;
}

#preloader .jumper > div:nth-child(2) {
  -webkit-animation-delay: 0.33333s;
  animation-delay: 0.33333s;
}

#preloader .jumper > div:nth-child(3) {
  -webkit-animation-delay: 0.66666s;
  animation-delay: 0.66666s;
}

@-webkit-keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes jumper {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* NEWS SECTION */

.news-hidden {
  display: none;
}

#spinner-news {
  margin: 0 auto;
}

#load-more-btn {
  margin: 0 auto;
}

/* Podrska section */
#podrska {
  background-image: url('../images/cod-sajt-sponbg.png');
}

#podrska-img-mobile {
  display: flex;
  justify-content: center;
}
